import { interpolate } from '@popmotion/popcorn';
import css from 'src/theme/css';
import { graphql } from 'gatsby';
import React, { forwardRef, useRef } from 'react';
import ColorProvider from 'src/components/ColorProvider';
import ImageGroup from 'src/components/ImageGroup';
import RichText from 'src/components/RichText';
import useAnimationFrame from 'src/hooks/useAnimationFrame';
import useBounds from 'src/hooks/useBounds';
import { useStore } from 'src/components/GlobalState';
import * as ease from 'src/utils/ease';

const Mask = forwardRef(({ position, bg }, ref) => (
  <div
    ref={ref}
    css={css({
      bg,
      position: 'absolute',
      top: position === 'bottom' ? 'auto' : 0,
      bottom: position === 'top' ? 'auto' : 0,
      left: position === 'right' ? 'auto' : 0,
      right: position === 'left' ? 'auto' : 0,
      height: position === 'top' || position === 'bottom' ? '20vh' : '100%',
      width: position === 'top' || position === 'bottom' ? '100%' : '20vw',
      transformOrigin: position,
    })}
  />
));

const RevealModule = ({ background, hasScrim, colorMode, text }) => {
  const outer = useRef();
  const scrim = useRef();
  const top = useRef();
  const left = useRef();
  const right = useRef();
  const bottom = useRef();
  const [ref, bounds] = useBounds();
  const reflow = useStore((state) => state.reflow);

  useAnimationFrame(() => {
    if (
      top.current &&
      bottom.current &&
      left.current &&
      right.current &&
      bounds.current
    ) {
      const scale = interpolate([-reflow.height, reflow.height], [1, 0], {
        ease: ease.inOutQuint,
      })(-bounds.current.y);

      top.current.style.transform = `scaleY(${scale})`;
      bottom.current.style.transform = `scaleY(${scale})`;
      left.current.style.transform = `scaleX(${scale})`;
      right.current.style.transform = `scaleX(${scale})`;
    }

    if (hasScrim && scrim.current) {
      const opacity = interpolate(
        [reflow.height * 0.5, reflow.height * 1.5],
        [0, 1]
      )(-bounds.current.y);
      scrim.current.style.opacity = opacity;
    }
  });

  const color = background.color || 'antiMode';

  return (
    <ColorProvider mode={colorMode}>
      <div css={css({ bg: color, position: 'relative' })} ref={ref}>
        <div
          css={{
            position: 'sticky',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            overflow: 'hidden',
          }}
          ref={outer}
        >
          <ImageGroup css={{ width: '100%', height: '100%' }} {...background} />
          <Mask position="top" bg={color} ref={top} />
          <Mask position="bottom" bg={color} ref={bottom} />
          <Mask position="left" bg={color} ref={left} />
          <Mask position="right" bg={color} ref={right} />
          <div
            ref={scrim}
            css={css({
              display: hasScrim ? 'block' : 'none',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              background: ({ colors, mode }) =>
                `linear-gradient(
                      to ${mode === 'dark' ? 'bottom' : 'top'},
                      ${colors.antiModeAlpha[2]},
                      ${colors.antiModeAlpha[8]})`,
            })}
          />
        </div>
        <RichText
          alignItems="center"
          textAlign="center"
          justifyContent="center"
          css={css({
            minHeight: '100vh',
            maxWidth: '70rem',
            mt: '50vh',
            mx: 'auto',
            px: 'pageMargin',
            py: 'sectionMargin',
            position: 'relative !important',
          })}
          {...text}
        />
      </div>
    </ColorProvider>
  );
};

export default RevealModule;

export const query = graphql`
  fragment RevealModuleFragment on ContentfulRevealModule {
    id
    slug
    colorMode
    hasScrim
    background {
      ...ImageGroupFragment
    }
    text {
      json
    }
  }
`;
